<template>
    <div class="page_box" v-loading="loading">
        <div class="form_box">
            <div class="form_flex">
                <div class="form_item">
                    <div class="form_title">定额工资：</div>
                    <div class="form_input1">
                        {{ form.dinge_sal||0 }}
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_title">结算工资：</div>
                    <div class="form_input1">
                        {{ form.jiesuan_sal||0 }}

                    </div>
                </div>

                <div class="form_item">
                    <div class="form_title">传票工资：</div>
                    <div class="form_input1">
                        {{ form.chuanpiao_sal||0 }}

                    </div>
                </div>
                <div class="form_item">
                    <div class="form_title">基本工资：</div>
                    <div class="form_input1">
                        {{ form.base_sal||0 }}

                    </div>
                </div>
                <div class="form_item">
                    <div class="form_title">岗位工资：</div>
                    <div class="form_input1">
                        {{ form.gangwei_sal||0 }}

                    </div>
                </div>
                <div class="form_item">
                    <div class="form_title">岗位补助：</div>
                    <div class="form_input1">
                        {{ form.dinge_sal||0 }}

                    </div>
                </div>
                <div class="form_item">
                    <div class="form_title">其他固定工资：</div>
                    <div class="form_input1">
                        {{ form.dinge_sal||0 }}

                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>

export default {
    name: "Customer_Information_form",
    props: {

    },
    data() {
        return {
            loading:false,
            shows: false,
            user_id:0,
            form:{
               
            },
        

        };
    },
    methods: {
        get_detial(){
            this.loading=true
            this.$api("empSalarySet", {
               userId:this.$route.query.id
            // userId:432

            }, "post").then((res) => {
                this.loading=false
                console.log('工资记录',res.data)
                if (res.code ==200) {
                    if(res.data.list.length>0){
                        this.form=res.data.list[0]
                    }
                }else{
                    alertErr(res.msg)
                }
            });
        },

       
    },
    computed: {
    },

    watch: {
        
    },


    created() {
        this.get_detial()

    },


};
</script>

<style scoped lang="less">
.page_box {
    padding-top: 50px;
    .form_box {
        .form_flex{
            border-bottom: 1px solid #EEEEEE ;
            padding-bottom: 23px;
            .form_item{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 18px;
                .form_title{
                    width: 126px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: right;
                    flex-shrink: 0;
                    span{
                        color: #FC5F03;
                        margin-right: 5px;
                    }

                }
                .form_input1{
                    width: 545px;
                    height: 40px;
                    background: #FBFBFB;
                    border-radius: 2px 2px 2px 2px;
                    border: 1px solid #D4D4D4;
                    text-align: left;
                    line-height: 40px;
                    padding: 0 13px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #7A7A7A;

                }
            }
        }
    }
}
</style>